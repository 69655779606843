import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';
import { Environments } from 'configs';
import { ResponseType } from 'models';
import moment from 'moment';
import { store } from 'store';
import { finishLoading, setLoading } from 'store/common';
import { FORMAT_DATE, ONE_MINUTE, STATUS_API, STATUS_CODE_API } from 'uniforms';
import KEY_STORAGE from 'uniforms/key-storage';
import { getCookie, removeCookie } from 'utils';

/* Create Axios instance with baseURL
 */
const axiosInstance = Axios.create({
    baseURL: Environments.baseUrl,
    /* set timeout タイムアウトを設定する*/
    timeout: 30000,
});

/* Sleep in specified milliseconds
 */
function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/* config request interceptor for all request APIs
 */
axiosInstance.interceptors.request.use(
    async config => {
        const expires_in = localStorage.getItem('expires_in') || '';
        let expiredDate = moment(expires_in).add(-ONE_MINUTE, 'seconds').format(FORMAT_DATE.FULL_DATE_TIME);
        let currentDate = moment(new Date()).format(FORMAT_DATE.FULL_DATE_TIME);

        // const isAppInitLoad = window.location.href.includes('liff.state');
        // if (moment(expiredDate).diff(moment(currentDate)) <= 0 && !isAppInitLoad) {
        if (moment(expiredDate).diff(moment(currentDate)) <= 0) {
            let url = window.location.href;
            localStorage.setItem(KEY_STORAGE.URL_REDIRECT, url);
            removeCookie('id_token');
            await Auth.federatedSignIn({ customProvider: 'LINE' });
        }

        let countForWaitFederatedSignIn = 0;
        let maxCountForWaitFederatedSignIn = 50;

        while (
            (getCookie('id_token') === '' || getCookie('id_token') === undefined) &&
            countForWaitFederatedSignIn < maxCountForWaitFederatedSignIn
        ) {
            await sleep(100);
            countForWaitFederatedSignIn++;
        }

        const id_token = getCookie('id_token');

        const param = config.method === 'get' ? config.params : config?.data;
        if (id_token && !param?.noToken) {
            config.headers['Authorization'] = `Bearer ${id_token}`;
            config.headers['Content-Type'] = `application/json`;
        }

        if (param?.noLoading === undefined) {
            store.dispatch(setLoading());
        }
        return config;
    },
    error => {
        const param = error.config.method === 'get' ? error.config.params : error.config?.data;
        if (param.noLoading === undefined) {
            store.dispatch(finishLoading());
        }
        Promise.reject(error);
    },
);

/* config response interceptor for all response from APIs
APIからのすべての応答の構成応答インターセプター*/
axiosInstance.interceptors.response.use(
    response => {
        const { config } = response;

        const param = config.method === 'get' ? config.params : config?.data;
        if (param?.noLoading === undefined) {
            store.dispatch(finishLoading());
        }

        return response;
    },
    async error => {
        const param = error.config.method === 'get' ? error.config.params : error.config?.data;
        // const isAppInitLoad = window.location.href.includes('liff.state');
        let errorResponse = {
            ...error,
        };
        // if (error.response.status === STATUS_CODE_API.NOT_AUTHORIZE && !isAppInitLoad) {
        if (error.response.status === STATUS_CODE_API.NOT_AUTHORIZE) {
            errorResponse = {
                ...error,
                data: {
                    error_code: STATUS_API.AUTHENTICATE,
                },
            };
            let url = window.location.href;
            localStorage.setItem(KEY_STORAGE.URL_REDIRECT, url);
            Auth.federatedSignIn({ customProvider: 'LINE' });
        }
        if (param.noLoading === undefined) {
            store.dispatch(finishLoading());
        }

        return errorResponse;
    },
);

/**
 * API POST method
 * @param url string
 * @param params any
 * @returns Promise type any
 */

const post = async <Request, Response>(url: string, params: Request): Promise<ResponseType<Response> | undefined> => {
    const res = await axiosInstance.post(url, params);

    return res.data;
};

/**
 * API PUT method
 * @param url string
 * @param params any
 * @returns Promise type any
 */

const put = async <Request, Response>(url: string, params: Request): Promise<ResponseType<Response> | undefined> => {
    const res = await axiosInstance.put(url, params);

    return res.data;
};

/**
 * API GET method
 * @param url string
 * @param data any
 * @returns Promise type any
 */
const get = async <Request, Response>(url: string, params: Request): Promise<ResponseType<Response> | undefined> => {
    const requestGetAxios: AxiosRequestConfig = {
        url: url,
        baseURL: Environments.baseUrl,
        method: 'GET',
        params,
    };

    const res = await axiosInstance.request(requestGetAxios);

    return res.data;
};

const ApiServices = {
    post,
    get,
    put,
};

export default ApiServices;
