import { CoordinateModel } from 'models';

const MODE = {
    REGISTRATION: 'registration',
    DETAIL: 'detail',
};

const LOCATION_DEFAULT: CoordinateModel = {
    coordinates: {
        lat: 35.70211,
        lng: 138.664556,
    },
    description: '',
    zoom: 16,
};

const CLEAR_STATUS = {
    UNCLEARED: '未クリア',
    CLEARED: 'クリア済',
};

const SPOT_LIST_TABS = {
    LIST: `一覧`,
    MAP: `地図`,
};

const STATUS_API = {
    SUCCESS: '0',
    VALIDATION: '20',
    FAILURE: '30',
    AUTHENTICATE: '50',
    DATABASE_EXCEPTION: '40',
    EXCEPTION: '99',
};

const STATUS_CODE_API = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    NOT_AUTHORIZE: 401,
    ERROR: 500,
};

const POINT_API_TRIGGER = {
    MYSPOT_DETAIL_POST: 'myspot-detail-post',
    MYSPOT_DETAIL_PUT_UPDATE: 'myspot-detail-put-update',
    MYSPOT_DETAIL_PUT_DELETE: 'myspot-detail-put-delete',
    SPOT_CLEAR_SUCCESS: 'spot-clear-success',
    SPOT_CLEAR_FAILURE: 'spot-clear-failure',
    USER_CLEAR_SUCCESS: 'user-clear-success',
    USER_CLEAR_FAILURE: 'user-clear-failure',
};

const FORMAT_DATE = {
    FULL_DATE_TIME: 'YYYY/MM/DD hh:mm:ss A',
    YEAR_MONTH_DAY_HOUR_MINUTE: 'YYYY年MM月DD日 HH:mm',
    YYYYMMDDHHmmss: 'YYYYMMDDHHmmssSSS',
};

const ONE_MINUTE = '60';

const ALERT_STATUS = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
};

const CATEGORY_OR_SPOT_TYPE = {
    SPOT_CATEGORY: 'spot_category',
    SPOT_TYPE: 'spot_type',
};

const SPOT_CLEAR_TYPE = {
    AR: { label: 'AR', value: 1 },
    QR: { label: 'QR', value: 2 },
    WORD: { label: 'キーワード', value: 3 },
    BUTTON: { label: 'ボタン', value: 4 },
    CARD: { label: 'タッチカード', value: 5 },
    CHOICE: { label: '選択肢', value: 6 },
    IMAGE: { label: '画像', value: 7 },
    COUPON: { label: 'クーポン', value: 8 },
};

const SPOT_CLEAR_TYPE_DISPLAY = {
    QR: { label: 'QR', value: 2 },
    WORD: { label: 'キーワード', value: 3 },
    BUTTON: { label: 'ボタン', value: 4 },
    CHOICE: { label: '選択肢', value: 6 },
    IMAGE: { label: '画像', value: 7 },
    COUPON: { label: 'クーポン', value: 8 },
};

const ACTION_CLEAR_TYPE = {
    AR: { label: 'AR', value: 1 },
    QR: { label: 'QR', value: 2 },
};

const CATEGORY_DEFAULT = {
    MISSION: { label: 'ミッション', value: 1 },
    COUPON: { label: 'クーポン', value: 2 },
};

const AGE_OPTION = [
    { label: '回答しない', value: '回答しない' },
    { label: '0-9', value: '0-9' },
    { label: '10-19', value: '10-19' },
    { label: '20-29', value: '20-29' },
    { label: '30-39', value: '30-39' },
    { label: '40-49', value: '40-49' },
    { label: '50-59', value: '50-59' },
    { label: '60-69', value: '60-69' },
    { label: '70-79', value: '70-79' },
    { label: '80-89', value: '80-89' },
    { label: '90-99', value: '90-99' },
    { label: '100-109', value: '100-109' },
];

const GENDER_OPTION = [
    { label: '回答しない', value: '回答しない' },
    { label: '男性', value: '男性' },
    { label: '女性', value: '女性' },
];

const AUTHORITY = {
    GENERAL: 'general',
    ADMIN: 'admin',
};

const CATEGORY_DATA = {
    '4': ['water_supply_other'],
    '3': ['manner', 'nature', 'culture'],
    '2': ['coupon'],
    '1': [
        'category1',
        'category2',
        'category3',
        'category4',
        'category5',
        'category6',
        'category7',
        'category8',
        'category9',
        'category10',
        'category11',
    ],
};

const OPERATION_LOG_ACTION = {
    INITIAL_DISPLAY: '初期表示',
    PRESS_BUTTON: '{{button_name}}ボタン押下',
    PRESS: '{{item_name}}を押下',
    SCAN_QR: 'QRコードの登録',
    UPLOAD_IMAGE: '画像をアップロード ',
    CLEAR_MISSION: 'ミッション{{item_name}}を完了する',
    USE_COUPON: 'クーポン{{item_name}}を完了する',
};

const SPOT_CATEGORY_TYPE = {
    MISSION: 'mission',
    COUPON: 'coupon',
};

const ENVIRONMENT_ID = {
    IRIOMOTE: 'iriomote',
    FRUITPARK: 'fruitpark',
    OKI: 'oki',
    YOSHINOKUMANO: 'yoshinokumano',
};

const ENV_CHANGE_USER_RICHMENU = [ENVIRONMENT_ID.IRIOMOTE];

const DEFAULT_LINK = 'https://google.com';

const SURVEY_LINK_URL = {
    IRIOMOTE: '',
    /* FRUITPARK:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=R_V95g2dTU-RYVHG7R99EczaF2YWZ3BLq9KgGogTBzdUOERFWDJFS01CN1BZVEtLUFdaNTMwODdQMy4u&ra0d01bcf0e26485fb24b0948ec5138e5=',
    */
    FRUITPARK:
        'https://forms.office.com/pages/responsepage.aspx?id=R_V95g2dTU-RYVHG7R99EaL-M9jHr3pDrTpQ2A4WLbZUREM0RkJUUFFKMkNMNFlXTFMxREpZMDFaTy4u&ra0d01bcf0e26485fb24b0948ec5138e5=',
    OKI: '',
    YOSHINOKUMANO: '',
};

export {
    MODE,
    LOCATION_DEFAULT,
    CLEAR_STATUS,
    SPOT_LIST_TABS,
    STATUS_API,
    STATUS_CODE_API,
    POINT_API_TRIGGER,
    FORMAT_DATE,
    ONE_MINUTE,
    ALERT_STATUS,
    CATEGORY_OR_SPOT_TYPE,
    SPOT_CLEAR_TYPE,
    ACTION_CLEAR_TYPE,
    CATEGORY_DEFAULT,
    SPOT_CLEAR_TYPE_DISPLAY,
    AGE_OPTION,
    GENDER_OPTION,
    AUTHORITY,
    CATEGORY_DATA,
    OPERATION_LOG_ACTION,
    SPOT_CATEGORY_TYPE,
    ENVIRONMENT_ID,
    ENV_CHANGE_USER_RICHMENU,
    SURVEY_LINK_URL,
    DEFAULT_LINK,
};
